import type { Router } from 'vue-router'

/**
 * setup client and mount app, any external imports should be code split via dynamic imports
 * this ensures that we optmize setup code for main entry into different chunks that can be
 * long term chached
 */
export async function client(): Promise<void> {
  // Start validating the anonymous/logged out user for the feature switch client
  const featureSwitchValidation = fetch('/fs/validate/LOGGED_OUT_ANONYMOUS')

  // Start loading all the packages we will need to set up the client
  const config = import('@attest/config')
  const appSetup = import('@attest/_/setup/app')
  const fs = import('@attest/feature-switch')
  const a3 = import('@attest/a3')
  const attestUser = import('@attest/user')
  const routerSetup = import('./setup/router')
  const trackingSetup = import('./setup/tracking')
  const progressBarSetup = import('./setup/progress-bar')
  const attestApi = import('@attest/_api')

  // setup config so that from this point onwards we can safely use
  // the runtime config
  await config.then(config => config.initialise())

  // Setup feature switch client before progressing so from this point on
  // we can stably use feature switches.
  const { fsInitialisationPromies } = await fs.then(async fs => {
    const data = await (await featureSwitchValidation).json()
    return { fsInitialisationPromies: fs.initialiseFeatureSwitchClient(data.hash) }
  })

  // BUG in vite where the import().then syntax doesn't correctly code split
  const [{ router, app }] = await Promise.all([
    (await appSetup).setupApp(),
    fsInitialisationPromies,
  ])

  if ('Cypress' in globalThis) {
    ;(globalThis as any).__INTERNALS__ = {
      router,
    }
  }

  await a3.then(a3 => a3.useSetupA3AxiosAuthenticator())

  // setup initial authentication of user
  const { profilePromise } = await a3.then(async a3 => {
    const a3Store = a3.useA3Store()
    const { useUserStore } = await attestUser
    const userStore = useUserStore()

    if (a3Store.isA3Available()) {
      if (!a3Store.isAuthenticated) {
        await a3Store.authenticate()
      }
      if (a3Store.isAuthenticated) {
        return { profilePromise: userStore.getUserAndAdditionalInfo() }
      }
    } else {
      await userStore.authenticate()
      if (userStore.isAuthenticated) {
        return { profilePromise: userStore.getUserAndAdditionalInfo() }
      }
    }
    return { profilePromise: Promise.resolve(undefined) }
  })

  router.beforeResolve(async () => {
    try {
      await profilePromise
      return true
    } catch {
      return true
    }
  })

  // setup router hooks
  await routerSetup.then(async r => r.useSetupRouterHooks(router))

  // setup essential application pre-resiqites
  await Promise.all([
    trackingSetup.then(tracking => tracking.setupTracking(router)),
    progressBarSetup.then(progressBar => progressBar.useSetupProgressBar(router)),
    attestApi.then(api =>
      api.interceptResponse(
        response => response,
        async error => {
          const { useA3Store } = await a3
          const a3Store = useA3Store()
          if (a3Store.isA3Available()) {
            throw error
          }

          if (error.response && error.response.status === 401) {
            await navigateToLogin(router)
          }

          throw error
        },
      ),
    ),
  ])

  // initialise router and mount app after initial resolving
  app.use(router)
  await router.isReady()
  app.mount('#app')

  await removeLoadingPlaceholder()
}

async function navigateToLogin(router: Router): Promise<void> {
  const { ROUTES } = await import('@attest/router')
  if (router.currentRoute.value.name === ROUTES.LOGIN.name) return
  globalThis.location.href = router.resolve({
    name: ROUTES.LOGIN.name,
    query: { redirect: router.currentRoute.value.fullPath },
  }).href
}

async function removeLoadingPlaceholder(): Promise<void> {
  const loading = document.getElementById('loading')
  if (!loading) return
  loading.dataset.loaded = 'true'
  loading.addEventListener('transitionend', () => {
    loading?.remove()
    document.getElementById('loading-style')?.remove()
  })
}
